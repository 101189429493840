const config = {
    'alma':'fa',
    onUpdate: (registration)=>{

        caches.keys().then(cacheNames => {
            cacheNames.forEach(cacheName => {
              caches.delete(cacheName);
            });
          });

        registration.unregister();

        window.location.reload();
    }
};


export default config;