import React from 'react';
import './App.css';
import logo from './logo_80.png';
import ParkingSlots from './ParkingSlots';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { useEffect } from 'react';

const firebaseConfig = {
  apiKey: "AIzaSyCRKXrNawnRnBd9kYQm9eMj_EuKDai-JR8",
  authDomain: "uf-onesignal-sandbox.firebaseapp.com",
  projectId: "uf-onesignal-sandbox",
  storageBucket: "uf-onesignal-sandbox.appspot.com",
  messagingSenderId: "238500882173",
  appId: "1:238500882173:web:5a558ac6181e65ec860094",
  measurementId: "G-CX5NWD68NQ"
};

function App() {
  return (
    <div className="App">
      
      <header className="App-header">
        <div className='logo-cont'><img src={logo} alt='NetGo parkoló'/></div>
        <h1>Parkoló <a href="https://batz.hu/" target="_blank">blank</a> <a href="https://parkolo.netgo.hu/a">noblank</a></h1>
      </header>
      
      <ParkingSlots/>

    </div>
  );
}

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default App;
