import React, { Component } from 'react';

export class ParkingSlots extends Component {

    interval = 0;

    constructor(props) {
      super(props);
      this.state = {online: navigator.onLine,parkingSlots:{},username:localStorage.getItem('username')};
    }
  
    componentDidMount() {
      
        this.setOnlineOrOffline();
        this.getJSON();

        this.interval = setInterval((e) => this.getJSON(),60000);
    }

    getJSON(){

        fetch(process.env.REACT_APP_API_SERVER_URL+'?allow_cache=true')
        .then(response => response.json())
        .then((data) =>{
            this.setState({
                parkingSlots:data
            })
        })
        .catch(error => console.error(error));

    }


    setOnlineOrOffline(){

        window.addEventListener("offline", (e) => {

            this.setState({
                online:false
            });

        });
          
        window.addEventListener("online", (e) => {

            this.setState({
                online:true
            });

            this.getJSON();

        });

    }

    handle_username(e){

        localStorage.setItem('username',e.target.value);

        this.setState({
            username:e.target.value
        });
        
    }
  
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    
    render() {

        const {online,parkingSlots} = this.state;

        return (
        <div>
            <section className='info-row'>
                <div className='is-online'>{online===true ? 'Online' : 'Offline'}</div>
                <div className='user-name'><input type='text' placeholder='Add meg neved!' onChange={(e)=>this.handle_username(e)} defaultValue={this.state.username}/></div>
            </section>
            
            <section className='parking-slots'>

            {Object.keys(parkingSlots).length !== 0 ? 
                Object.keys(parkingSlots).map((key) => {
                    return <div  key={parkingSlots[key].id} className={"slot "+(parkingSlots[key]['is-free'] ? "free" : "reserved")} id={parkingSlots[key].id}>
                        <span className='status'>
                            {(parkingSlots[key]['is-free']) ? 'Szabad' : 'Foglalt'} 
                        </span>
                        {(!parkingSlots[key]['is-free']) ? (<span className='username'>{parkingSlots[key].reserver} - {parkingSlots[key]['reserved-from']} </span>): ''}
                    </div>
                })
            : ''}
            
            </section>

        </div>
        );
    }
  }
  
  export default ParkingSlots;